import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Grid, Text, Button } from "basis";

import {
  Container,
  PortalBackground,
  ErrorMessage,
  ERROR_CODES,
  LoadingMessage,
} from "../../../../components";
import Promotions from "../../../../components/Promotions/Promotions";

import { BREAKPOINT, COLOR } from "../../../../components/theme";

import { logger } from "../../../../core";
import { isBrowser } from "../../../../utils";
import { poll } from "../../../../utils/retry";
import {
  TRANSACTION_STATUS,
  PROMO_SELECT_PLACEHOLDER,
} from "../../../../core/constants";
import { useDefaultAuth } from "../../../../core/auth";

import * as api from "../../utils/api";
import { Cart, Footer, MerchantHeader } from "../../components";
import Pending from "./components/Pending";
import CustomerDetails from "./components/CustomerDetails";

const StyledContainer = styled.div`
  border-radius: 10px;
  background-color: ${COLOR.WHITE};
  padding: 30px 15px;
  margin-top: 30px;

  @media (min-width: ${BREAKPOINT.SIZE_MOBILE}) {
    padding: 30px;
  }
  a {
    font-size: 15px;
    span {
      color: ${COLOR.DARK_GREY};
      border-color: ${COLOR.DARK_GREY};

      &:hover {
        border-color: ${COLOR.DARK_GREY};
        background: transparent;
      }
    }
  }
  .error {
    border-bottom: 5px solid ${COLOR.RED};
    display: block;
    width: 100%;
    padding: 15px 0;
    margin-bottom: 15px;

    p {
      color: ${COLOR.RED} !important;
    }
  }
`;

const StyledButtonContainer = styled.div`
  border-radius: 10px;
  background-color: ${COLOR.WHITE};
  padding: 05px 0 0 0;
  text-align: right;
  @media (min-width: ${BREAKPOINT.SIZE_MOBILE}) {
    padding: 05px 0 0 0;
  }

  button {
    min-width: 215px;
  }
`;

if (isBrowser()) {
  window.history.pushState(null, document.title, window.location.href);
  window.addEventListener("popstate", function(event) {
    window.history.pushState(null, document.title, window.location.href);
  });
}

const Summary = () => {
  const [order, setOrder] = useState();
  const [promotions, setPromotions] = useState();
  const [selectedPromotion, setSelectedPromotion] = useState();
  const [error, setError] = useState(null);
  const [submissionError, setSubmissionError] = useState();
  const [promoCode, setPromoCode] = useState();
  const [showPending, setPendingStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isPromotionSelected, setIsPromotionSelected] = useState(false);

  const {
    error: authError,
    params,
    profile: merchantProfile,
  } = useDefaultAuth();

  const { transactionId } = params;

  const getTransactionDetails = async () => {
    if (!transactionId) {
      setError(ERROR_CODES.INVALID_REQUEST);
      return;
    }

    try {
      const response = await api.getTransaction(transactionId);

      if (!response) {
        setError(ERROR_CODES.INVALID_REQUEST);
        return;
      }

      const { selectedPromotion, promotions } = response;
      setPromotions(promotions);

      setOrder(response);
      if (
        !showPending &&
        (order?.status === TRANSACTION_STATUS.SUBMITTED ||
          order?.status === TRANSACTION_STATUS.INITIATED)
      ) {
        setError(ERROR_CODES.INVALID_REQUEST);
        return;
      }

      if (promotions?.length === 1) {
        setIsPromotionSelected(true);
      }

      setSelectedPromotion(selectedPromotion);
      setPromoCode(selectedPromotion?.value);

      if (response) {
        setLoading(false);
      }
      return response;
    } catch (err) {
      logger.error(err.message);
      setError(ERROR_CODES.INVALID_REQUEST);
    }
  };

  const linkSent = async () => {
    if (isBrowser() && (window.location.hash === "#Link-Sent" || showPending)) {
      setPendingStatus(true);
      await poll(
        getTransactionDetails,
        (order) => {
          return (
            order.status === TRANSACTION_STATUS.INITIATED ||
            order.status === TRANSACTION_STATUS.SUBMITTED
          );
        },
        5000
      );
    }
  };

  useEffect(() => {
    linkSent();
    getTransactionDetails();
  }, [transactionId, showPending]);

  const onSubmit = async (e) => {
    setLoading(true);
    const { success } = await api.sendEncryptedLink({
      merchantId: merchantProfile?.merchantId,
      transactionId: transactionId,
      promotionReference: promoCode,
      email: "",
      phoneNumber: order.customer.phone,
    });
    if (success) {
      setLoading(false);
      setPendingStatus(true);
      if (isBrowser()) {
        window.location.hash = "Link-Sent";
      }
      return;
    }
    setSubmissionError(true);
    return;
  };

  const onManualPaymentClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { response, success } = await api.getManualPayment({
      transactionId,
    });
    if (success) {
      window.location.replace(response);
    } else {
      setLoading(false);
      setError(new Error(ERROR_CODES.INVALID_REQUEST));
      return;
    }
  };

  const handlePromotionChange = (value) => {
    if (value === PROMO_SELECT_PLACEHOLDER) {
      setIsPromotionSelected(false);
      return;
    }
    const matchingPromotion = promotions.find(
      (promotion) => promotion.value === value
    );

    setSelectedPromotion(matchingPromotion);
    setPromoCode(value);
    setIsPromotionSelected(true);
  };

  const Error = ({ type }) => (
    <Grid preset="page" rowsGap={4}>
      <Grid.Item colSpan="all" colSpan-lg="2-10" colSpan-xl="4-8">
        <ErrorMessage
          type={type}
          additionalParams={{
            minimumAmount: order?.minimumAmount,
            currency: order?.currency,
            urlCancel: order?.urlCancel,
            transactionId: order?.transactionId,
          }}
        />
      </Grid.Item>
    </Grid>
  );

  if (authError) {
    return <Error type={authError} />;
  }

  if (error) {
    return <Error type={error} />;
  }

  if (loading) {
    return <LoadingMessage />;
  }

  if (order) {
    return (
      <PortalBackground>
        <MerchantHeader hideProfile disableHeaderClick />
        <Container maxWidth="1000">
          <Grid preset="page" rowsGap={7}>
            <Grid.Item colSpan="all" colSpan-md="0-4" colSpan-lg="0-5">
              <StyledContainer>
                <Cart
                  order={order}
                  merchantDetails={merchantProfile}
                  transactionId={transactionId}
                />
                <Footer order={order} />
              </StyledContainer>
            </Grid.Item>
            <Grid.Item colSpan="all" colSpan-md="5-12" colSpan-lg="6-12">
              <StyledContainer>
                {showPending ? (
                  <Pending
                    order={order}
                    onManualPaymentClick={onManualPaymentClick}
                  />
                ) : (
                  <>
                    <Promotions
                      title="Step 1"
                      promotions={promotions}
                      selectedPromotion={selectedPromotion}
                      order={order}
                      onChange={handlePromotionChange}
                    />
                    <CustomerDetails
                      order={order}
                      onSubmit={onSubmit}
                      hideEmail
                      isValid={isPromotionSelected}
                    />
                    <StyledButtonContainer>
                      <Button onClick={(e) => onManualPaymentClick(e)}>
                        Manual Payment
                      </Button>
                    </StyledButtonContainer>
                  </>
                )}

                {submissionError && (
                  <span className="form-totals error">
                    <Text>
                      An error has occured. Please check your selections and try
                      again.
                    </Text>
                  </span>
                )}
              </StyledContainer>
            </Grid.Item>
          </Grid>
        </Container>
      </PortalBackground>
    );
  }

  return <LoadingMessage />;
};

export default Summary;
