import React, { useState, useEffect } from "react";
import { Container, Stack, Text, Button, Form, Input } from "basis";
import styled from "styled-components";
import { BREAKPOINT, COLOR } from "../../../../../components/theme";
import { CURRENCY } from "../../../../../core/constants";
import { EMAIL_REGEX, PHONE_REGEX, PHONE_REGEX_NZ } from "../../../constants";

const FormContainer = styled.div`
  .buttonHolderPOS {
    justify-content: space-between;
    margin-top: 10px;
    > div {
      opacity: 1;
      margin: 5px 0 10px;
    }
  }

  button {
    min-width: 215px;
  }

  form > div {
    opacity: 1;
  }
  label {
    margin-top: 15px;
    > span {
      display: none;
    }
  }
  input {
    border: ${COLOR.MID_GREY} 1px solid;
    background-color: ${COLOR.WHITE};
    width: 100%;
    border-radius: 5px;
    padding: 5px 20px;
    font-size: 16px;
    margin: 0;
    &:disabled {
      opacity: 0.5;
      background-color: ${COLOR.GREY};
    }
    &::selection {
      background: ${COLOR.GREY};
    }
  }
  div[data-testid="mobile-number"] {
    div[id^="input-aux"] {
      display: none;
    }
  }
  a {
    align-self: center;
    cursor: pointer;
    font-size: 15px;
    span {
      color: ${COLOR.DARK_GREY};
      border-color: ${COLOR.DARK_GREY};

      &:hover {
        border-color: ${COLOR.DARK_GREY};
        background: transparent;
      }
    }
  }

  .validation-error {
    width: 100%;
    p {
      border-top: ${COLOR.RED} 3px solid;
      color: ${COLOR.RED};
      padding-top: 5px;
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
`;

const StyledButtonContainer = styled.div`
  border-radius: 10px;
  background-color: ${COLOR.WHITE};
  padding: 05px 0 0 0;
  text-align: right;
  @media (min-width: ${BREAKPOINT.SIZE_MOBILE}) {
    padding: 05px 0 0 0;
  }

  button {
    min-width: 215px;
  }
`;

const CustomerDetails = ({ order, onSubmit, hideEmail, isValid }) => {
  const [customerDetailsError, setCustomerDetailsError] = useState(false);
  const [customerEmail, setCustomerEmail] = useState(
    hideEmail ? "" : order.customer.email
  );
  const [customerPhone, setCustomerPhone] = useState(order.customer.phone);
  const [validEmail, setValidEmail] = useState(true);
  const [validPhone, setValidPhone] = useState(true);

  const formInitialValues = {
    customerPhone: customerPhone,
    customerEmail: customerEmail,
  };

  useEffect(() => {
    validatePhone({ value: customerPhone });
    validateCustomerDetails();
  }, [customerPhone]);

  const validateEmail = ({ value }) => {
    setValidEmail(true);
    if (value.length) {
      setValidEmail(EMAIL_REGEX.test(String(value).toLowerCase()));
    }
    setCustomerEmail(value);
  };

  const validatePhone = ({ value }) => {
    if (!value) {
      setValidPhone(false);
      return;
    }
    if (order.currency === CURRENCY.NZD) {
      setValidPhone(PHONE_REGEX_NZ.test(String(value).toLowerCase()));
      setCustomerPhone(value);
      return;
    }
    if (value.length) {
      setValidPhone(PHONE_REGEX.test(String(value).toLowerCase()));
    }
    setCustomerPhone(value);
  };

  const validateCustomerDetails = (e) => {
    if (customerEmail === "" && customerPhone === "") {
      setCustomerDetailsError(true);
      return;
    }

    if (!validEmail || !validPhone) {
      return;
    }

    order.customer.phone = customerPhone;
    order.customer.email = customerEmail;

    setCustomerDetailsError(false);
  };

  return (
    <>
      <Container padding="8 0 4">
        <Stack gap="1">
          <Text textStyle="heading5">Step 2</Text>
          {hideEmail ? (
            <Text textStyle="body2">
              Send customer encrypted link to their mobile no. to complete their
              Interest Free purchase.
            </Text>
          ) : (
            <Text textStyle="body2">
              Send customer encrypted link to their mobile no. and email address
              to complete their Interest Free purchase.
            </Text>
          )}
        </Stack>
      </Container>
      <FormContainer>
        <Form initialValues={formInitialValues} onSubmit={onSubmit}>
          <Input
            name="customerPhone"
            variant="numeric"
            type="tel"
            label="Mobile Number"
            onChange={(value) => validatePhone(value)}
            optional
            testId="mobile-number"
            maxLength="16"
          />

          {!validPhone && (
            <span className="validation-error">
              {customerPhone === "" ? (
                <Text>Phone number is required for sending payment link.</Text>
              ) : (
                <Text>The phone number entered is invalid</Text>
              )}
            </span>
          )}
          {!hideEmail && (
            <Input
              name="customerEmail"
              type="email"
              label="Email"
              onChange={(value) => validateEmail(value)}
              optional
              maxLength="128"
            />
          )}
          {!validEmail && (
            <span className="validation-error">
              <Text>The email address entered is invalid</Text>
            </span>
          )}

          <div className="buttonHolderPOS">
            <StyledButtonContainer>
              <Button type="submit" disabled={!validPhone || !isValid}>
                Send Payment link
              </Button>
            </StyledButtonContainer>
          </div>
        </Form>
      </FormContainer>
    </>
  );
};

export default CustomerDetails;
